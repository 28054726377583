import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import logo from "../assets/images/logo.png";
const Pagesidebar = (props) => {

    const [selectedmenu, setSelectedMenu] = useState(props.mainmenu);
    const [selectedsubmenu, setSelectedSubMenu] = useState(props.menu);
    const [version, setVersion] = useState(false);
    const activeSetSubenu = (submenu='') => {
        setSelectedSubMenu(submenu);
    }

    const showVersion = () =>{
        setVersion(!version) ;
    }
    const activeSet = (menu) => {

        if(selectedmenu=== menu ){
            setSelectedMenu('');
        }else{
            setSelectedMenu(menu);
        }
    }


    return (
        <React.Fragment>


            <div className={`pageSidebar ${props.showsidebar ? "show" : "hide"} `}>
                <Link  onClick={() => showVersion()}  className="logo">
                    <img src={logo} className="logoImage sidebarLogo" alt="logo"/>
                </Link>
                <ul className="sidebarList toggleSideBar__contents">
                    <li id="listItem-0" onClick={() => activeSet('Dashboard')}
                        className={`hasInnerList ${(selectedmenu) === 'Dashboard' ? "activeIn" : ""}  closed`}>
                        <Link to="/dashboard">{'Dashboard'}</Link></li>
                    <li id="listItem-1" onClick={() => activeSet('Report')}
                        className={`hasInnerList ${(selectedmenu) === 'Report' ? "activeIn" : ""}  closed`}>
                        <Link to="/report">{'Report'}</Link></li>
                    <li id="listItem-2" onClick={() => activeSet('Masters')} menu={selectedmenu}
                        className={`iconMasters ${selectedmenu === 'Masters' ? "active" : ""}  `}>
                        {'Masters'}</li>
                    <ul>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'Branch' ? "activeIn" : ""} `}>
                            <Link to="/list-branch"
                                  onClick={() => activeSetSubenu
                                  ('Branch')}>{'Branch'}
                            </Link>
                        </li>
                    </ul>



                </ul>
                {version &&
                <p>v3.4</p>
                }
            </div>

        </React.Fragment>

    )
}
export default Pagesidebar
