import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddBranches} from "../actions/User";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";


const AddBranch = () => {
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {branch: '',branchar:'',branchcode:'', selactive:''}
    );

    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );

    const [statusloader, setStatusloader] = useState(false);
    const [errorbranchstatus, setErrorBranchstatus] = useState(false);
    const [errorcodestatus, setErrorCodestatus] = useState(false);
    const addbranchdata = useSelector(state => state.UserReducer.addbranchdata) ;

    let history = useHistory();
    let validCodeError = false;
    let validBranchError = false;
    const dispatch = useDispatch();


    useEffect(() => {
        if(submit === true){
            if(addbranchdata.success == true){
                setStatusloader(false);
                setStatus('New Branch Saved') ;
                setInputValues({ "branch" : '',"branchar" : '', "branchcode" : '', "selactive": ''});
            }
            if(addbranchdata.success == false){
                setStatusloader(false);
                setStatus(addbranchdata.data) ;
            }
        }

    },[addbranchdata]);




    const submitForm = () => {
        if(!inputValues.branch){
           validBranchError = true;
           setErrorBranchstatus(true);
        } else{
           validBranchError = false;
           setErrorBranchstatus(false);
        }
        if(!inputValues.branchcode ){
           validCodeError = true;
           setErrorCodestatus(true);
        } else{
            validCodeError = false;
            setErrorCodestatus(false);
        }

        if(!validBranchError && !validCodeError) {
            const myObj = {
                "en": inputValues.branch,
                "ar": inputValues.branchar,
                "c": inputValues.branchcode,
                "active": inputValues.selactive
            };

            setStatusloader(true);
            setSubmit(true);
            dispatch(AddBranches(inputValues.branchcode, inputValues.branch, inputValues.selactive)) ;

        }

    }

    const resetForm = () => {
        setStatus('');
        setInputValues({ "branch" : '',"branchar" : '', "branchcode" : '', "selactive": ''});
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

    return (
        <React.Fragment>

            <Pagesidebar menu="Branch" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">

                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>

                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h4>Add Branch</h4>
                    </div>



                    <div className="p30">
                        { status !== ""  ? (
                            <span className="messageIn">{status}</span>
                        ) : ''
                        }

                        <div className="fullFlexBox">


                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Branch</label>

                                    <input value={inputValues.branch} name="branch"
                                           className={`csForm__item__input ${errorbranchstatus ? 'error' : ''} `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>
                            {/*<div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Branch Arabic</label>

                                    <input value={inputValues.branchar} name="branchar"
                                           className={`csForm__item__input `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>*/}

                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Branch Code</label>

                                    <input value={inputValues.branchcode} name="branchcode"
                                           className={`csForm__item__input ${errorcodestatus ? 'error' : ''} `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>

                            <div className="halfBlock">
                                <Selectbox label="Is Active" name="selactive" values={yesnovalue}
                                           onChange={handleChange} selvalue={inputValues.selactive}/>

                            </div>



                        </div>

                    </div>

                    <div className="p30">
                        <div className="flexCC buttonWrap">
                            <span onClick={resetForm}
                                  className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                            <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

export default AddBranch
